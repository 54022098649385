////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(246, 244, 236, 1);
}
.bg-secondaryColor {
  background-color: rgba(246, 244, 236, 1);
}
.bg-thirdColor {
  background-color: rgba(246, 244, 236, 1);
}
.bg-fourthColor {
  background-color: rgba(194, 185, 93, 1);
}
.bg-fifthColor {
  background-color: rgba(238, 225, 225, 1);
}
.bg-sixthColor {
  background-color: rgb(0, 0, 0);
}
.bg-seventhColor {
  background-color: rgb(0, 0, 0);
}
